import { useState } from 'react'; 
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./theme";
import UserContext from "./UserContext";
import Login from './Login';
import WebApp from "./WebApp";
import PrivateRoute from "./PrivateRoute";
import './App.css';

function App() {

  const [email, setEmail] = useState(localStorage.getItem("id") || undefined);
  const [pw, setPw] = useState(localStorage.getItem("word") || undefined);
  const [accounts, setAccounts] = useState([]);
  const [jobs, setJobs] = useState({jobs:[], contractors: {}});
  const [selectedAccount, setSelectedAccount] = useState(localStorage.getItem("selectedAccount") || undefined);
  const [selectedJob, setSelectedJob] = useState(undefined);
  const [selectedOrder, setSelectedOrder] = useState(undefined);
  const [selectedTicket, setSelectedTicket] = useState(undefined);
  const [metric, setMetric] = useState(localStorage.getItem('metric') || "1");
  const [isContractor, setIsContractor] = useState(false);
  const [expanded, setExpanded] = useState(undefined);
  const updateEmail = (val) => {
    localStorage.setItem("id", val);
    setEmail(val);
  }
  const updatePw = (val) => {
    localStorage.setItem("word", val);
    setPw(val);
  }
  const updateAccounts = (val) => setAccounts(val);
  const updateSelectedAccount = (val) => {
    if (val !== undefined) {
      localStorage.setItem("selectedAccount", val);
    }
    setSelectedAccount(val)
    setExpanded(undefined);
  };
  const updateJobs = (val) => setJobs(val);
  const updateSelectedJob = (val) => setSelectedJob(val);
  const updateSelectedOrder = (val) => setSelectedOrder(val);
  const updateSelectedTicket = (val) => setSelectedTicket(val);
  const updateMetric = (val) => {
    setMetric(val);
    if (val !== metric && val !== undefined) {
      localStorage.setItem('metric', val);
    }
  }
  const updateIsContractor = (val) => setIsContractor(val);
  const updateExpanded = (val) => setExpanded(val);

  const userInfo = {
    email,
    updateEmail,
    pw,
    updatePw,
    accounts,
    updateAccounts,
    selectedAccount,
    updateSelectedAccount,
    selectedJob,
    updateSelectedJob,
    selectedOrder,
    updateSelectedOrder,
    selectedTicket,
    updateSelectedTicket,
    metric,
    updateMetric,
    jobs,
    updateJobs,
    isContractor,
    updateIsContractor,
    expanded,
    updateExpanded
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
        <Router>
          <UserContext.Provider value={userInfo}>
          <Switch>
            <PrivateRoute exact path="/" component={WebApp} />
            <Route path="/login" component={Login} />
            <Route path="/order" component={WebApp} />
            <Route path="/ticket" component={WebApp} />
          </Switch>
          </UserContext.Provider>
        </Router>
    </ThemeProvider>
  );
}

export default App;
