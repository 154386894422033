import { useContext } from "react";
import { useHistory } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import makeStyles from "@material-ui/core/styles/makeStyles";
import UserContext from "../../UserContext";

const useStyles = makeStyles(
  ({ spacing, palette: { primary }, typography: { pxToRem } }) => {
    return {
      root: {
        width: '100%',
        paddingLeft: spacing(2),
        paddingRight: spacing(2),
        overflow: "auto",
        "& div": {
          paddingTop: spacing(1),
          paddingBottom: spacing(1),
          borderBottom: "1px solid #333",
          cursor: "pointer",
          "&:last-of-type": {
            borderBottom: "none"
          }
        }
      },
      heading: {
        padding: spacing(2),
        paddingBottom: 0,
      },
    };
  },
);

export default () => {
  const classes = useStyles();
  const { updateSelectedJob, jobs } = useContext(UserContext);
  const history = useHistory();

  const handleClick = (i) => {
    updateSelectedJob(i);
    history.push({
      pathname: '/order',
      search: `?id=${i}`
    });
  }

  return (
    <div className={classes.root}>
      {jobs?.jobs?.sort((a, b) => (a.name > b.name) ? 1 : -1).map((item, idx) => {
        return (
          <div key={`${item?.id}${idx}`} onClick={() => handleClick(item.id)}>
            <Typography variant="h3">{item.name}</Typography>
            <Typography>{item.contractor.name}</Typography>
          </div>
        )
      })}
    </div>
  )
};