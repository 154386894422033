import { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { formatInTimeZone } from "date-fns-tz";
import UserContext from "../../UserContext";
import { convertMmCeil, convertDegC, convertM3, convertMl, convertL, cleanString } from "./Orders";
import MapSection from "./map/Map";

const useStyles = makeStyles(
  ({ spacing, palette: { primary }, typography: { pxToRem } }) => {
    return {
      root: {
        width: '100%',
        height: '100%',
        display: "flex",
        flexDirection: "column"
      },
      mapHolder: {
        flexGrow: "1"
      },
      specHolder: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      },
      back: {
        fontSize: pxToRem(40),
        fontWeight: "bold",
        cursor: "pointer",
        width: "5%",
        marginRight: pxToRem(8)
      },
      specs: {
        display: "grid",
        gridTemplateColumns: "18% 18% 18% 28% 18%",
        width: "95%",
        gridTemplateRows: "auto",
        padding: pxToRem(8),
        "& div": {
          fontSize: "10px",
          "&:nth-child(-n+5)": {
            fontWeight: "bold"
          },
          "&:nth-child(n+11):nth-child(-n+15)": {
            fontWeight: "bold"
          },
          "&:nth-child(n+6):nth-child(-n+10)": {
            marginBottom: spacing(1.5)
          }
        },
      },
    };
  },
);

export default ({ ticket }) => {
  const classes = useStyles();
  const history = useHistory();
  const { metric, accounts, selectedAccount, updateSelectedJob } = useContext(UserContext);
  const tz = accounts?.find((itm) => itm.id === selectedAccount)?.defaultTimeZoneId;
  const [toggle, setToggle] = useState(true);

  const {
    truckNumber,
    arrivalTimeUTCSeconds,
    pouredVolumeM3,
    loadVolumeM3,
    currentSlumpAtDischargeMm,
    targetSlumpMm,
    deliveryCyclePhase,
    totalRevsAtDischarge,
    totalRevs,
    waterAddedL,
    waterAddedAtDischargeL,
    waterRemainingL,
    waterRemainingAtDischargeL,
    batchingLocation,
    temperatureAtDischargeC,
    temperatureC,
    verifiAdmixAdded,
    loadedTimeUTCSeconds,
    dischargeTimeUTCSeconds
  } = ticket || {};

  const ageMinutes = () => {
    if (deliveryCyclePhase === "END_POUR") {
      return `${Math.round(((dischargeTimeUTCSeconds * 1000) - (loadedTimeUTCSeconds * 1000)) / 60000)}m`
    };
    if (loadedTimeUTCSeconds) {
      return `${Math.round((new Date().getTime() - (loadedTimeUTCSeconds * 1000)) / 60000)}m`;
    };
    return "--"
  }

  useEffect(() => {
    if (verifiAdmixAdded) {
      const interval = setInterval(() => {
        setToggle(!toggle);
      }, 3000);
      return () => clearInterval(interval);
    }
  }, [toggle, verifiAdmixAdded]);

  const handleBack = (i) => {
    updateSelectedJob(i);
    history.push({
      pathname: '/order',
      search: `?id=${i}`
    });
  }

  return (
    <div className={classes.root}>
      <div className={classes.specHolder}>
        <div className={classes.back} onClick={() => handleBack(ticket?.jobId)}>
          &lt;
        </div>
        <div className={classes.specs}>
          <div>{`TRUCK`}</div>
          <div>{`ARRIVAL`}</div>
          <div>{`TEMP`}</div>
          <div>{`POURED`}</div>
          <div>{`SLUMP/TGT`}</div>
          <div>{truckNumber}</div>
          <div>{arrivalTimeUTCSeconds ? formatInTimeZone(new Date(arrivalTimeUTCSeconds * 1000), tz, "h:mmaaa") : "--"}</div>
          <div>{convertDegC(temperatureAtDischargeC || temperatureC, metric)}</div>
          <div>
            {`${convertM3(pouredVolumeM3, metric, false)}/${convertM3(loadVolumeM3, metric)}`}
          </div>
          <div>{`${loadVolumeM3 > 2.3 ? convertMmCeil(currentSlumpAtDischargeMm, metric, false) : "SML"}/${convertMmCeil(targetSlumpMm, metric)}`}</div>
          <div>{`STATUS`}</div>
          <div>{`TOTAL REV`}</div>
          <div>{`AGE`}</div>
          <div>{toggle ? "H20 ADD/REM" : "ADMIX ADD"}</div>
          <div>{`BATCHED`}</div>
          <div>{deliveryCyclePhase ? cleanString(deliveryCyclePhase) : "--"}</div>
          <div>{totalRevsAtDischarge || totalRevs ? Math.ceil(totalRevsAtDischarge || totalRevs) : "--"}</div>
          <div>{ageMinutes() || "--"}</div>
          <div>
            {toggle
                ? `${convertL(waterAddedAtDischargeL || waterAddedL, metric, false)}/${convertL(waterRemainingAtDischargeL || waterRemainingL, metric)}`
                : `${convertMl(verifiAdmixAdded, metric)}`
            }
          </div>
          <div>{batchingLocation}</div>
        </div>
      </div>
      <div className={classes.mapHolder}>
        <MapSection ticket={ticket} />
      </div>
    </div>
  )
};