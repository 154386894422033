import "typeface-open-sans";
import "typeface-montserrat";
import { createTheme } from '@material-ui/core/styles';

// Blue
const ALICE_BLUE = "#E4F3FF";
const CURIOUS_BLUE = "#3D85D2";
const DARK_CERULEAN = "#003482";
const DENIM = "#055BBB";
const FUN_BLUE = "#385487";
const PRUSSIAN_BLUE = "#002E62";
const VIKING = "#3B9FC4";
const CERULEAN = "#007CBB";

// Black
const BLACK = "#000000";
const NERO = "#212121";

// Green
const BILBAO = "#33806E";
const DARK_GREEN = "#023417";
const FERN_GREEN = "#4E8B45";
const FROSTED_MINT = "#DFEDE0";
const VERDUN_GREEN = "#335F00";
const OLIVE_GREEN = "#6F8E6B";

// Grey
const ALUMINIUM = "#859197";
const ECLIPSE = "#363636";
const MATTERHORN = "#555";
const SHUTTLE_GREY = "#51626F";
const WHITE_SMOKE = "#EFEFEF";
const WEDGEWOOD = "#596B7D";

// Purple
const DARK_PURPLE = "#872583";
const HOT_PURPLE = "#4D0059";

// White
const SNOW = "#F9F9F9";
const WHITE = "#FFFFFF";

// Red
const MAHOGANY = "#D12532";
const PERSIAN_RED = "#BA1E37";

// Other colors
const DUSTY_ROSE = "#D5A0A0";
const BURNT_ORANGE = "#B45F31";
const GAMBOGE = "#E0A000";
const KHAKI = "#D7CF5E";
const SEAL_BROWN = "#4D1006";

// Fonts
const FONT_LIGHT = 300;
const FONT_REGULAR = 400;
const FONT_SEMI_BOLD = 600;
const FONT_BOLD = 700;

const FONT_FAMILY = "Open Sans";
const FONT_FAMILY_MONTSERRAT = "Montserrat";

const FONT_SIZE = 14;
const HTML_FONT_SIZE = 16;

const pxToRem = (size) => {
  const coef = FONT_SIZE / 14;
  const rem = (size / HTML_FONT_SIZE) * coef;
  return `${rem}rem`;
};

export default createTheme({
  palette: {
    primary: {
      main: DENIM,
      light: CURIOUS_BLUE,
      dark: PRUSSIAN_BLUE,
    },
    secondary: {
      main: SNOW,
      light: WHITE,
    },
    text: {
      primary: BLACK,
      secondary: SHUTTLE_GREY,
    },
    grey: {
      50: SNOW,
      200: WHITE_SMOKE,
      500: ALUMINIUM,
      A700: MATTERHORN,
      800: ECLIPSE,
      900: NERO,
    },
    itmPhaseColor: {
      waitingToLoad: DUSTY_ROSE,
      loading: PERSIAN_RED,
      loaded: GAMBOGE,
      toJob: BILBAO,
      onSite: DARK_PURPLE,
      pouring: DARK_CERULEAN,
      washing: VIKING,
      returnToPlant: BURNT_ORANGE,
      ignitionOff: ALUMINIUM,
      unknown: SHUTTLE_GREY,
    },
    mapCardError: {
      gpsError: VERDUN_GREEN,
      communicationError: HOT_PURPLE,
    },
    loadDetailColor: {
      actualSlump: FERN_GREEN,
      targetSlump: DARK_GREEN,
      discharge: FROSTED_MINT,
      waterAdded: FUN_BLUE,
      maxWater: MAHOGANY,
      drumSpeed: WEDGEWOOD,
      admixture: SEAL_BROWN,
      totalRevs: KHAKI,
      loadTemp: OLIVE_GREEN,
    },
    info: {
      main: CERULEAN,
      dark: DENIM,
      light: ALICE_BLUE,
    },
  },
  typography: {
    fontFamily: FONT_FAMILY,
    fontFamilySecondary: FONT_FAMILY_MONTSERRAT,
    fontWeightLight: FONT_LIGHT,
    fontWeightRegular: FONT_REGULAR,
    fontWeightMedium: FONT_SEMI_BOLD,
    fontWeightBold: FONT_BOLD,
    fontSize: FONT_SIZE,
    htmlFontSize: HTML_FONT_SIZE,
    h1: {
      fontSize: pxToRem(24),
      fontWeight: FONT_SEMI_BOLD,
      lineHeight: pxToRem(24),
      letterSpacing: pxToRem(1),
    },
    h2: {
      fontSize: pxToRem(20),
      fontWeight: FONT_SEMI_BOLD,
      lineHeight: pxToRem(25),
      letterSpacing: pxToRem(0.5),
    },
    h3: {
      fontSize: pxToRem(16),
      fontWeight: FONT_SEMI_BOLD,
      lineHeight: pxToRem(20),
    },
    h4: {
      fontSize: pxToRem(16),
      fontWeight: FONT_BOLD,
      lineHeight: pxToRem(20),
    },
    h5: {
      fontSize: pxToRem(14),
      fontWeight: FONT_SEMI_BOLD,
      lineHeight: pxToRem(18),
      letterSpacing: pxToRem(0.5),
    },
    h6: {
      fontSize: pxToRem(12),
      fontWeight: FONT_SEMI_BOLD,
      lineHeight: pxToRem(15),
    },
    subtitle1: {
      fontSize: pxToRem(18),
      letterSpacing: pxToRem(0.5),
      lineHeight: pxToRem(23),
    },
    subtitle2: {
      fontSize: pxToRem(12),
      fontWeight: FONT_SEMI_BOLD,
      lineHeight: pxToRem(15),
    },
    body1: {
      fontSize: pxToRem(14),
      fontWeight: FONT_REGULAR,
      lineHeight: pxToRem(20),
      letterSpacing: pxToRem(0.5),
    },
    body2: {
      fontSize: pxToRem(12),
      fontWeight: FONT_REGULAR,
      lineHeight: pxToRem(15),
    },
    button: {
      fontFamily: FONT_FAMILY_MONTSERRAT,
      lineHeight: pxToRem(16),
    },
    caption: {
      fontSize: pxToRem(12),
      letterSpacing: pxToRem(0),
      lineHeight: pxToRem(17),
    },
    overline: {
      fontSize: pxToRem(10),
      letterSpacing: pxToRem(0),
      lineHeight: pxToRem(16),
      textTransform: "none",
    },
  },
  // Set default CSS for components by overriding styles
  overrides: {
    MuiButton: {
      text: {
        fontWeight: FONT_LIGHT,
        fontSize: pxToRem(12),
      },
      sizeSmall: {
        fontSize: pxToRem(10),
      },
      contained: {
        fontWeight: FONT_SEMI_BOLD,
        fontSize: pxToRem(14),
      },
      outlined: {
        fontWeight: FONT_SEMI_BOLD,
        fontSize: pxToRem(12),
        textTransform: "none",
        backgroundColor: WHITE,
      },
    },
    MuiChip: {
      root: {
        backgroundColor: ALICE_BLUE,
        fontSize: pxToRem(14),
      },
    },
    MuiAccordionDetails: {
      root: {
        display: "block"
      }
    },
    MuiFormControlLabel: {
      label: {
        fontSize: pxToRem(16),
      },
      root: {
        cursor: "default",
      },
    },
    MuiFormControl: {
      root: {
        display: "block",
      }
    },
    MuiInputBase: {
      input: {
        // IE: 11 & Edge: Remove reveal icon
        "&::-ms-reveal": {
          display: "none",
        },
        "&:focus::-webkit-contacts-auto-fill-button": {
          opacity: 0,
        },
      },
      root: {
        display: "block"
      },
      multiline: {
        backgroundColor: WHITE,
        padding: pxToRem(16)
      },
      underline: {
        marginTop: "0px"
      }
    },
    MuiMenuItem: {
      root: {
        fontSize: pxToRem(16),
        minHeight: "auto",
      },
    },
    MuiDivider: {
      light: {
        backgroundColor: WHITE_SMOKE,
      },
    },
    MuiSvgIcon: {
      fontSizeSmall: {
        fontSize: pxToRem(14),
      },
    },
    MuiToolbar: {
      dense: {
        minHeight: pxToRem(40),
      },
    },
    MuiSelect: {
      select: {
        padding: pxToRem(16),
        paddingTop: 0,
        marginRight: pxToRem(16)
      },
    },
    MuiTableCell: {
      root: {
        padding: `8px 4px`
      },
      body: {
        whiteSpace: "nowrap",
        textOverflow: "ellipsis"
      },
      head: {
        paddingBottom: pxToRem(8),
        backgroundColor: CURIOUS_BLUE,
        color: WHITE,
        whiteSpace: "nowrap",
        lineHeight: pxToRem(14),
      }
    }
  },
  // Set default prop values for components
  props: {
    MuiCheckbox: {
      disableRipple: true, // Removes the ripple animation from all checkboxes
    },
  },
});