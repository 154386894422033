import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/Input';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useForm, Controller } from "react-hook-form";
import UserContext from "../UserContext";

const useStyles = makeStyles(
  ({  spacing, palette: { primary }, typography: { pxToRem } }) => {
    return {
      main: {
        height: "100vh",
        display: "flex",
        alignItems: "center",
        textAlign: "center",
      },
      flex: {
        display: "flex",
        alignItems: "center"
      },
      container: {
        height: "100vh",
        width: "100%",
      },
      set1: {
        position: "fixed",
        top: "40%",
        transform: "translate(0, -50%)",
        width: "100%",
        padding: spacing(2)
      },
      set2: {
        position: "fixed",
        top: "80%",
        textAlign: "center",
        width: "100%",
      },
      block: {
        display: "block",
        "&:first-of-type": {
          marginBottom: spacing(4),
        },
      },
      wide: {
        width: "100%"
      }
    };
  },
);

export default () =>{
  const { handleSubmit, control } = useForm();
  const { updateEmail, updatePw } = useContext(UserContext);
  const [hasError, setError] = useState(false);
  const history = useHistory();
  const onSubmit = data => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("verifi-user", data.email.toLowerCase());
    myHeaders.append("verifi-user-pass",data.password);
    updatePw(data.password);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };

    fetch(`https://${process.env.REACT_APP_BACKEND_HOST_NAME}/rs/api/2.0/user`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result) {
          updateEmail(result.email);
          history.push('/')
        }
      })
      .catch(error => {
        console.log('error', error);
        setError(true);
      });
  };
  const classes = useStyles();

  

  return (
    <div className={classes.container}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.set1}>
            {hasError && <Alert variant="filled" severity="error">Username or Password incorrect. Please try again.</Alert>}
            <div className={classes.block}>
              <Typography variant="h6">Email Address</Typography>
              <Controller
                name="email"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    label="email"
                    variant="filled"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helpertext={error ? error.message : null}
                  />
                )}
                rules={{ required: 'Email required' }}
              />
            </div><div className={classes.block}>
              <Typography variant="h6">Password</Typography>
              <Controller
                name="password"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    label="password"
                    variant="filled"
                    value={value}
                    onChange={onChange}
                    type="password"
                    error={!!error}
                    helpertext={error ? error.message : null}
                  />
                )}
                rules={{ required: 'Password required' }}
              />
            </div>
          </div>
          <div className={classes.set2}>
            <Button
                color="primary"
                size="large"
                variant="contained"
                type="submit"
                onClick={handleSubmit(onSubmit)}
                className={classes.wide}
            >
              SUBMIT
            </Button>
          </div>
        </form>
    </div>
  )
};
