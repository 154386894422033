import { useContext, useState, useEffect } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { formatInTimeZone } from "date-fns-tz";
import UserContext from "../../../UserContext";
import { convertMmCeil, convertDegC, cleanString, convertM3 } from "../Orders";

const useStyles = makeStyles(
  ({palette: { primary, secondary }}) => {
    return {
      gray: {
        color: "#aaa",
      },
      fixedLeft: {
        position: "sticky",
        left: 0,
        borderRight: "1px solid #ccc"
      },
      gridScroller: {
        overflow: "scroll",
      },
      
      grid: {
        display: "grid",
        gridTemplateColumns: "repeat(14, min-content)",
        width: "fit-content",
        "& >div": {
          padding: "8px 12px 8px 8px",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden"
        }
      },
      XLgrid: {
        display: "grid",
        gridTemplateColumns: "repeat(14, 10%)",
        width: "fit-content",
        "& >div": {
          padding: "8px 12px 8px 8px",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden"
        }
      },
      
      gridEntry: {
        backgroundColor: "white",
        cursor: "pointer"
      },
      
      gridHeader: {
        backgroundColor: primary.light,
        color: secondary.light,
        position: "sticky",
        top: 0,
        zIndex: 1,
        "&:first-of-type": {
          zIndex: 2,
        }
      },
      sortBy: {
        cursor: "pointer",
        "&::before": {
          border: "4px solid transparent",
          content: "''",
          display: "block",
          height: 0,
          right: "0px",
          top: "50%",
          position: "absolute",
          width: 0,
          borderBottomColor: "#ccc",
          marginTop: "-5px",
        },
      },
      sorted: {
        "&::before": {
          border: "4px solid transparent",
          content: "''",
          display: "block",
          height: 0,
          right: "0px",
          top: "50%",
          position: "absolute",
          width: 0,
          borderBottomColor: "#fff",
          marginTop: "-5px"
        },
      }    
    };
  },
);

export default ({tickets: currentTickets, handleTicket}) => {
  const classes = useStyles();
  const { metric, accounts, selectedAccount } = useContext(UserContext);
  const tz = accounts?.find((itm) => itm.id === selectedAccount)?.defaultTimeZoneId;
  const isXs = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const [sortType, setSortType] = useState("time");
  const [tickets, setTickets] = useState(undefined);

  useEffect(() => {
    setTickets(currentTickets);
  }, [currentTickets, setTickets]);

  const handleSortChange = (val) => {
    setSortType(val);
    const sortWork = [...tickets.sort((a, b) => (a.arrivalTimeUTCSeconds > b.arrivalTimeUTCSeconds) ? 1 : -1)];
    if (val === "place") {
      setTickets(sortWork.sort((a, b) => (a.batchingLocation.toLowerCase() > b.batchingLocation.toLowerCase()) ? 1 : -1));
    } else {
      setTickets(currentTickets);
    }
  }

  const handleNM = (val, status) => {
    if (status === "LOAD_NOT_MANAGED" || status === "MIX_REQUIREMENT_NOT_MET") {
      return `NM`;
    }
    if (status === "DRUM_NOT_IN_CHARGE") {
      return "--";
    }
    return convertMmCeil(val, metric);
  }

  const calcArrivalAge = (arrivalTime, loadedTime) => {
    const diff = arrivalTime && loadedTime && Math.round((arrivalTime - loadedTime)/60);
    return diff ?? "--";
  };

  // we don't currently have the numbers to calculate
  const calcPourTime = (dischargeTime) => {
    const pourTime = dischargeTime && formatInTimeZone(new Date(dischargeTime * 1000), tz, "h:mmaaa");
    return pourTime || "--";
  }

  const calcWaitTime = (arrivalTime, dischargeTime) => {
    const diff = arrivalTime && dischargeTime &&  dischargeTime - arrivalTime;
    const minutes = diff && Math.round(diff / 60);
    return minutes ? `${minutes} min` : "--";
  };

  return (
    <>
      <div className={classes.gridScroller}>
        <div className={isXs ? classes.grid : classes.XLgrid}>
          <div className={`${classes.gridHeader} ${classes.fixedLeft}`}>TRUCK</div>
          <div className={classes.gridHeader}>ARRIVAL</div>
          <div onClick={() => handleSortChange("time")} className={`${classes.gridHeader} ${sortType === "time" ? classes.sorted : classes.sortBy}`}>LOADED</div>
          <div className={classes.gridHeader}>SLUMP</div>
          <div className={classes.gridHeader}>TICKET NO</div>
          <div className={classes.gridHeader}>REVS</div>
          <div className={classes.gridHeader}>TEMP</div>
          <div className={classes.gridHeader}>STATUS</div>
          <div className={classes.gridHeader}>{`${metric === "1" ? `CM` : `CY`} ORDERED`}</div>
          <div className={classes.gridHeader}>{metric === "1" ? `CM` : `CY`} POURED</div>
          <div
            onClick={() => handleSortChange("place")}
            className={`${classes.gridHeader} ${sortType === "place" ? classes.sorted : classes.sortBy}`}
          >PLANT</div>
          <div className={classes.gridHeader}>WAIT TIME</div>
          <div className={classes.gridHeader}>POUR TIME</div>
          <div className={classes.gridHeader}>ARRIVAL AGE</div>
          {tickets?.map((row, idx) => (
            <>
              <div
                className={`${classes.gridEntry} ${classes.fixedLeft} ${row.deliveryCyclePhase === "END_POUR" ? classes.gray : ""}`}
                onClick={() => handleTicket(tickets[idx])} 
              >
                {row.truckNumber}
              </div>
              {/* date is off as usable timestamp by a factor of 1000 */}
              <div
                className={`${classes.gridEntry} ${row.deliveryCyclePhase === "END_POUR" ? classes.gray : ""}`}
                onClick={() => handleTicket(tickets[idx])}  
              >
                {row.arrivalTimeUTCSeconds ? formatInTimeZone(new Date(row.arrivalTimeUTCSeconds * 1000), tz, "h:mmaaa") : "--"}
              </div>
              <div
                className={`${classes.gridEntry} ${row.deliveryCyclePhase === "END_POUR" ? classes.gray : ""}`}
                onClick={() => handleTicket(tickets[idx])}
              >
                {row.loadedTimeUTCSeconds ? formatInTimeZone(new Date(row.loadedTimeUTCSeconds * 1000), tz, "h:mmaaa") : "--"}
              </div>
              <div
                className={`${classes.gridEntry} ${row.deliveryCyclePhase === "END_POUR" ? classes.gray : ""}`}
                onClick={() => handleTicket(tickets[idx])}  
              >
                {handleNM(row.currentSlumpAtDischargeMm || row.currentSlumpMm, row.currentSlumpAtDischargeExtendedStatus)}
              </div>
              <div
                className={`${classes.gridEntry} ${row.deliveryCyclePhase === "END_POUR" ? classes.gray : ""}`}
                onClick={() => handleTicket(tickets[idx])}  
              >
                {row.ticketNumber}
              </div>
              <div
                className={`${classes.gridEntry} ${row.deliveryCyclePhase === "END_POUR" ? classes.gray : ""}`}
                onClick={() => handleTicket(tickets[idx])}
              >
                {Math.floor(row.totalRevs ?? row.totalRevsAtDischarge)}
              </div>
              <div
                className={`${classes.gridEntry} ${row.deliveryCyclePhase === "END_POUR" ? classes.gray : ""}`}
                onClick={() => handleTicket(tickets[idx])}
              >
                {convertDegC(row.temperatureC ?? row.temperatureAtDischargeC, metric)}
              </div>
              <div
                className={`${classes.gridEntry} ${row.deliveryCyclePhase === "END_POUR" ? classes.gray : ""}`}
                onClick={() => handleTicket(tickets[idx])}
              >
                {cleanString(row.deliveryCyclePhase)}
              </div>
              <div
                className={`${classes.gridEntry} ${row.deliveryCyclePhase === "END_POUR" ? classes.gray : ""}`}
                onClick={() => handleTicket(tickets[idx])}
              >
                {convertM3(row.loadVolumeM3, metric)}
              </div>
              <div
                className={`${classes.gridEntry} ${row.deliveryCyclePhase === "END_POUR" ? classes.gray : ""}`}
                onClick={() => handleTicket(tickets[idx])}  
              >
                {convertM3(row.pouredVolumeM3, metric, false)}
              </div>
              <div
                className={`${classes.gridEntry} ${row.deliveryCyclePhase === "END_POUR" ? classes.gray : ""}`}
                onClick={() => handleTicket(tickets[idx])}
              >
                {row.batchingLocation}
              </div>
              <div
                className={`${classes.gridEntry} ${row.deliveryCyclePhase === "END_POUR" ? classes.gray : ""}`}
                onClick={() => handleTicket(tickets[idx])}
              >
                {calcWaitTime(row.arrivalTimeUTCSeconds, row.dischargeTimeUTCSeconds)}
              </div>
              <div
                className={`${classes.gridEntry} ${row.deliveryCyclePhase === "END_POUR" ? classes.gray : ""}`}
                onClick={() => handleTicket(tickets[idx])}
              >
                {calcPourTime(row.dischargeTimeUTCSeconds)}
              </div>
              <div
                className={`${classes.gridEntry} ${row.deliveryCyclePhase === "END_POUR" ? classes.gray : ""}`}
                onClick={() => handleTicket(tickets[idx])}
              >
                {calcArrivalAge(row.arrivalTimeUTCSeconds, row.loadedTimeUTCSeconds)}
              </div>
            </>
          ))}
        </div>
      </div>
    </>
  );
}