import { useState, useEffect, useContext } from "react";
import Typography from '@material-ui/core/Typography';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import UserContext from "../../UserContext";

const useStyles = makeStyles(
  ({ spacing, typography: { pxToRem } }) => {
    return {
      root: {
        width: '100%',
        paddingRight: spacing(2),
        backgroundColor: "#055BBB",
      },
      heading: {
        padding: spacing(2),
      },
      fluidItem: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        marginBottom: spacing(2),
        "& div": {
          flex: "50%",
          color: "#fff",
          overflow: "hidden",
          "&:last-of-type": {
            marginTop: pxToRem(6)
          }
        },
      }

    };
  },
);

export default ({ accounts }) => {
  const classes = useStyles();
  const [firstAccount] = useState(accounts[0] | undefined);
  const { selectedAccount, updateSelectedAccount } = useContext(UserContext);

  const handleChange= (e) => updateSelectedAccount(e.target.value);
  return (
    <div className={classes.root}>
      <div className={classes.fluidItem}>
        <div><Typography variant="h3" className={classes.heading}>Account</Typography></div>
        <div>{
          accounts.filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i).length === 1
            ? <Typography>{accounts[0]?.name}</Typography>
            : (
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedAccount ?? ""}
                onChange={handleChange}
                margin="none"
              >
                {
                  accounts?.map(({ id, name }) => <MenuItem value={id} key={id}>{name}</MenuItem>)
                }
              </Select>)
        }</div>
      </div>  
    </div>
  )
};