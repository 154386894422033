import { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import makeStyles from "@material-ui/core/styles/makeStyles";
import UserContext from "../../UserContext";

const useStyles = makeStyles(
  ({ spacing, palette: { primary, info }, typography: { pxToRem } }) => {
    return {
      root: {
        width: '100%',
        overflow: "auto",
      },
      heading: {
        padding: spacing(2),
        paddingBottom: 0,
      },
      addr: {
        borderBottom: `1px solid ${primary.dark}`,
        fontSize: spacing(2),
        padding: `${spacing(2)}px 0px`,
        cursor: "pointer",
        "&:last-of-type": {
          borderBottom: "none"
        },
      },
      blue: {
        backgroundColor: info.light,
        borderBottom: `2px solid ${primary.dark}`
      }
    };
  },
);

export default ({ contractors }) => {
  const classes = useStyles();
  const { updateSelectedJob, expanded, updateExpanded } = useContext(UserContext);
  const history = useHistory();
  const [localContractors, setLocalContractors] = useState(contractors);

  useEffect(() => {
    setLocalContractors(contractors);
  }, [contractors])

  const handleClick = (i, ex) => {
    updateSelectedJob(i);
    history.push({
      pathname: '/order',
      search: `?id=${i}`
    });
  }

  const handleExpanded = (e, ex) => {
      if (expanded === `panel${ex}`) {
        updateExpanded(undefined)
      } else {
        updateExpanded(`panel${ex}`)
      }
  };

  return (
    <div className={classes.root}>
      {localContractors && Object.keys(localContractors)
        .sort((a, b) => (a > b) ? 1 : -1)
        .map((item, idx) => {
        return (
          <div key={`${idx}`}>
            <Accordion
              key={idx}
              expanded={expanded === `panel${idx}`}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${idx}-content`}
                id={`panel${idx}-header`}
                onClick={(e) => {
                  e.stopPropagation();
                  handleExpanded(e, idx);
                }}
                className={classes.blue}
              >
                <Typography variant="h4">{localContractors[item][0].contractor.name}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {localContractors[item]
                  .filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i)
                  .sort((a, b) => (a.name > b.name) ? 1 : -1)
                  .map((entry, index) => (
                    <div
                      className={classes.addr}
                      key={`${entry.address}${index}`}
                      id={`${entry.address}${index}`}
                      onClick={() => handleClick(entry.id, idx)}
                    >
                      <Typography variant="h5">{entry.name}</Typography>
                    </div>
                  ))
                }
              </AccordionDetails>
            </Accordion>
            <Typography variant="h3">{}</Typography>
          </div>
        )
      })}
    </div>
  )
};