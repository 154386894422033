import { useContext, useEffect, useState, useCallback } from "react";
import { useLocation, useHistory } from 'react-router-dom';
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useForm } from "react-hook-form";
import Button from '@material-ui/core/Button';
import { format } from "date-fns";
import UserContext from "../UserContext";
import Account from "./selects/Account";
import Jobs from "./selects/Jobs";
import Contracts from "./selects/Contracts";
import Orders from "./selects/Orders";
import Ticket from "./selects/Ticket";
import Metrics from "../components/Metrics";

const useStyles = makeStyles(
  ({ spacing, palette: { primary, secondary }, typography: { pxToRem } }) => {
    return {
      main: {
        height: "100vh",
        display: "flex",
        alignItems: "center",
        textAlign: "center",
      },
      flex: {
        display: "flex",
        alignItems: "center"
      },
      container: {
        height: "100vh",
        width: "100%",
      },
      set1: {
        position: "fixed",
        top: "40%",
        transform: "translate(0, -50%)",
        width: "100%",
        padding: spacing(2)
      },
      set2: {
        position: "fixed",
        top: "80%",
        textAlign: "center",
        width: "100%",
      },
      block: {
        display: "block",
        "&:first-of-type": {
          marginBottom: spacing(4),
        },
      },
      wide: {
        width: "100%",
        "&:last-of-type": {
          marginTop: spacing(4)
        }
      },
      toggleParent: {
        display: "flex",
        justifyContent: "center",
        "& div": {
          border: `1px solid ${primary.dark}`,
          padding: `${spacing(1)}px ${spacing(2)}px`,
          fontSize: spacing(2),
          fontWeight: "bold",
          cursor: "pointer",
          flex: "1 1 0px",
          textAlign: "center"
        }
      },
      selected: {
        backgroundColor: primary.main,
        color: secondary.main
      }
    };
  },
);

export default () => {

  const classes = useStyles();
  const history = useHistory();
  const { control } = useForm();
  const location = useLocation();
  const parsedTicket = JSON.parse(sessionStorage.getItem("currentTicket"));
  const [ticket, setTicket] = useState(parsedTicket || undefined);
  const handleTicket = (ticket) => {
    setTicket(ticket);
    sessionStorage.setItem("currentTicket", JSON.stringify(ticket));
    history.push({
      pathname: '/ticket',
      search: `?ticketId=${ticket.ticketId}`
    });
  }
  const view = () => {
    const path = location.pathname;
    switch (path) {
      case "/":
        return "jobs";
      case "/order":
        return "order";
      case "/ticket":
        return "ticket";
      default:
        return "jobs";
    }
  };

  const {
    email,
    accounts,
    updateEmail,
    updatePw,
    jobs,
    updateJobs,
    selectedJob,
    updateAccounts,
    selectedAccount,
    pw,
    updateSelectedAccount,
    updateSelectedJob,
    updateSelectedOrder,
    isContractor,
    updateIsContractor
  } = useContext(UserContext);

  // we're using this to prevent a refresh loop in a useeffect.
  // is there a better way? I'm sure. You figure it out.
  const [localAccount, setLocalAccount] = useState(selectedAccount);

  const returnHeaders = useCallback(() => {
    let theseHeaders = new Headers();
    theseHeaders.append("Content-Type", "application/json");
    theseHeaders.append("verifi-user", email);
    theseHeaders.append("verifi-user-pass", pw);
    return theseHeaders;
  }, [email, pw]);

  const xhrDate = format(new Date(), "yyyyMMdd");

  const reduceArray = (arr) => arr.reduce((total, current, orig) => {
    const ids = total.jobs.map((item) => item.id);
    const contract = current.contractor.name.toString() || "none";  
    if (!ids.includes(current.id)) {
      total.jobs.push(current);
    }
    if (!total.contractors.hasOwnProperty(contract)) {
      total.contractors[contract] = [current];
    } else {
      total.contractors[contract].push(current);
    }
    return total;
  }, {jobs:[], contractors: {}});

  useEffect(() => {
    var myHeaders = returnHeaders();
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };
    if (accounts.length === 0) {
      fetch(`https://${process.env.REACT_APP_BACKEND_HOST_NAME}/rs/api/2.0/account/all`, requestOptions)
        .then(response => response.json())
        .then(result => {
          updateAccounts(result);
          if (result.length > 0) {
            updateSelectedAccount(localStorage.getItem("selectedAccount") || result[0].id);
            if (localStorage.getItem("selectedAccount")) {
              setLocalAccount(undefined);
            }
          }
        })
        .catch(error => {
          console.log('error', error);
        });
    }
  }, [accounts.length, returnHeaders, updateAccounts, updateSelectedAccount]);

  useEffect(() => {
    var myHeaders = returnHeaders();
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    };
    if (selectedAccount && selectedAccount !== localAccount) {
      fetch(`https://${process.env.REACT_APP_BACKEND_HOST_NAME}/rs/api/2.0/job?accountId=${selectedAccount}&dayYYYYMMDD=${xhrDate}`, requestOptions)
        .then(response => response.json())
        // .then(result => updateJobs(reduceArray(result).jobs.sort((a, b) => (a.name > b.name) ? 1 : -1)))
        .then(result => updateJobs(reduceArray(result)))
        .then(setLocalAccount(selectedAccount))
        .catch(error => {
          console.log('error', error);
        });
    }
  }, [localAccount, returnHeaders, selectedAccount, updateJobs, xhrDate]);

  const ToggleType = () => (
    <div className={classes.toggleParent}>
      <div
        className={!isContractor ? classes.selected : ""}
        onClick={() => updateIsContractor(false)}
      >
        Jobs
      </div>
      <div
        className={isContractor ? classes.selected : ""}
        onClick={() => updateIsContractor(true)}
      >
        Contractors
      </div>
    </div>
  );

  return (
    <div className={classes.container}>
        {view() === "jobs" && <Metrics control={control} />}
        {view() === "jobs" && <Account accounts={accounts} />}
        {view() === "jobs" && <ToggleType />}
        {view() === "jobs" && !isContractor && <Jobs />}
        {view() === "jobs" && isContractor && <Contracts contractors={jobs.contractors} />}
        {view() === "order" && <Orders jobs={jobs.jobs} handleTicket={handleTicket} />}
        {view() === "ticket" && <Ticket ticket={ticket} />}
        <Button
          color="secondary"
          size="large"
          variant="contained"
          type="submit"
          onClick={() => {
            localStorage.removeItem("id");
            localStorage.removeItem("word");
            localStorage.removeItem("selectedAccount");
            updateEmail(undefined);
            updatePw(undefined);
            updateAccounts([]);
            updateJobs([]);
            updateSelectedAccount(undefined);
            updateSelectedJob(undefined);
            updateSelectedOrder(undefined);
            history.push('/login');
          }}
          className={classes.wide}
        >
          LOG OUT
        </Button>
    </div >
  )
};
